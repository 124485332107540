import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageComprendreAuroresBoreales: allPagesCompilationYaml(
      filter: { code: { eq: "comprendreAuroresBoreales" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "guideAuroresBoreales"
            "photographierAuroresBoreales"
            "galerieAuroresBoreales"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesLeslignesDeChampsMagnetiquesDunTrouCoronal"
            "auroresBorealesKp888Sur9"
            "auroreTaiga"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesLExperienceTerella"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUnovaleAuroral"
            "auroresBorealesStructureMagnetosphere"
            "auroresBorealesLaluminescence"
            "auroresBorealesUnbilletDe200KronerVerso"
            "auroresBorealesUnbilletDe200KronerRecto"
            "auroresBorealesLemodelePlanetaireDeBohr-Rutherford"
            "auroresBorealesLeventSolaireSouffleSurLaTerreDeformantLaMagnetosphere"
          ]
        }
      }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUneautreGravureDauroreDeFridtjofNansen"
            "auroresBorealesTachesSolaires"
            "auroresBorealesUntrouCoronal"
            "auroresBorealesLamagnetosphere"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPaysageMicro: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesLemodeleDeBohr-Rutherford"] } }
    ) {
      ...FragmentImagePaysageMicro
    }
    imagesPortraitTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUnportraitDeFridtjofNansen"
            "auroresBorealesUnegravureDauroreDeFridtjofNansen"
            "auroresBorealesLemodeleDeBohr-Rutherford"
            "auroresBorealesLescouchesDeLatmosphereTerrestre"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitTresPetit
    }
    imagesGif: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesEjectiondeMasseCoronale"] } }
    ) {
      ...FragmentImageEtLegendeGif
    }
  }
`

export default function Comprendreauroresboreales() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageComprendreAuroresBoreales")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPaysageTresPetit",
    "imagesPaysageMicro",
    "imagesPortraitTresPetit",
    "imagesGif"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Les aurores boréales et australes ont pour origine un processus
          naturel extrêmement complexe. Bien que ce processus recèle encore bien
          des secrets (la Nasa a envoyé de nouveaux satellites en mars 2015 pour
          encore mieux les comprendre), en voici les principaux points clefs.
        </p>
        <p>
          <strong>
            N’étant pas physicien professionnel, cet article est une
            vulgarisation issue de mes lectures et des échanges que j’ai pu
            avoir avec des professionnels Norvégiens.
          </strong>
        </p>
      </Section>
      <Section titre="Un peu d’histoire…">
        <h3>Croyances et légendes</h3>
        <p>
          Pour expliquer la formation des aurores boréales, les civilisations
          arctiques ont depuis tous les temps imaginé de{" "}
          <strong>nombreuses légendes.</strong> Les Sami racontent que c’est la
          neige soulevée par la queue du renard polaire qui monte vers le ciel
          pour former les aurores. Quant aux Inuits du Groenland, ils racontent
          que ce sont les âmes des morts qui jouent avec le crâne des morses…
        </p>
        <p>
          Les peuples asiatiques ont aussi leurs croyances. 2000 ans avant J.C,
          une impératrice Chinoise avait du mal à concevoir un enfant. On
          raconte que quelque temps après avoir vu une aurore boréale dans le
          ciel de son pays, elle tomba enceinte. C’est pourquoi on rencontre de
          nombreux couples asiatiques en Laponie l’hiver : concevoir sous une
          aurore boréale offrirait à son enfant un fantastique destin.
        </p>
        <h3>Fridtjof Nansen</h3>
        <p>
          Les explorateurs polaires ont été les <strong>premiers</strong> à
          raconter aux contrées civilisées la beauté des aurores. Un des plus
          célèbres, Fridtjof Nansen, a tenté d’atteindre le pôle Nord avec son
          bateau le « Fram » en 1895-96, en utilisant la dérive de la banquise.
        </p>
        <p>
          Pendant ce temps, il écrivit un livre « Vers le pôle » (« Farthest
          North », à feuilleter{" "}
          <Lien urlExterne="http://gallica.bnf.fr/ark:/12148/bpt6k28932d/f1.image">
            ici sur le site de la BNF
          </Lien>
          ) contenant des récits et illustrations d’aurores boréales.
        </p>
        <p>
          Un musée lui a également été consacré à Oslo. Le Fram y est exposé.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnportraitDeFridtjofNansen"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnegravureDauroreDeFridtjofNansen"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUneautreGravureDauroreDeFridtjofNansen"
            )}
            langue={page.langue}
          />
        </div>
        <h3>Kristian Birkeland</h3>
        <p>
          Kristian Birkeland est un physicien norvégien connu pour avoir été le
          premier en 1895 à <strong>reproduire des aurores</strong> en
          laboratoire lors d’expériences « <strong>Terella</strong> » (petite
          Terre en latin). Il découvrit que les aurores polaires résultaient
          d’une interaction entre les particules de vent solaire et le champ
          magnétique terrestre. Les nombreuses contributions à la science de
          Kristian Birkeland l’ont amené à être proposé 8 fois au prix Nobel :
          quatre fois en chimie et quatre fois en physique.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesLExperienceTerella")}
          langue={page.langue}
        />
        <p>
          La Norvège lui a rendu hommage en créant un billant de 200 couronnes
          (l’équivalent du billet de 20 euros chez nous) à son effigie. Il est
          décoré de dessins rappelant ses expériences, ses découvertes, et les
          aurores boréales.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnbilletDe200KronerRecto"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesUnbilletDe200KronerVerso"
            )}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="Du côté du Soleil…">
        <h3>Le vent solaire</h3>
        <p>
          Les aurores boréales ont pour origine le Soleil. Celui-ci{" "}
          <strong>éjecte</strong> un flux continu de plasma (du gaz
          électriquement chargé) dans toutes les directions autour de lui. Sa
          consistance (densité, vitesse) est liée à l’activité solaire :
          explosions, trous coronaux…
        </p>
        <p>
          La zone d’influence de ce vent s’appelle l’héliosphère et elle s’étend
          bien au-delà de l’orbite de Pluton. La fin de cette zone s’appelle
          l’héliopause. La sonde Voyager 1, lancée en 1977, l’a atteint en 2002
          après 25 ans de voyage.
        </p>
        <h3>Les taches solaires</h3>
        <p>
          Les taches sont le siège de{" "}
          <strong>puissants phénomènes magnétiques</strong> à la surface du
          soleil. Ceux-ci freinent les phénomènes de convection qui se
          produisent sous la surface du soleil, ce qui a pour effet de refroidir
          la zone de gaz qui se trouve autour. Comme le gaz y est moins chaud,
          il est moins lumineux et donc plus sombre : ce sont les taches
          solaires.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesTachesSolaires")}
          langue={page.langue}
        />
        <p>
          Cependant, les taches ne sont pas forcément à l’origine des aurores
          boréales. Celles-ci sont de simples régions de gaz plus froids que les
          gaz environnants. Pour qu’une tache soit susceptible de former une
          aurore boréale sur Terre, il faut une éruption, puis peut-être une
          éjection de masse coronale.
        </p>
        <h3>Éruptions et éjections de masse coronale</h3>
        <p>
          Les éruptions puis les éjections de masse coronale ont souvent pour
          origine les taches solaires. Celles-ci se forment souvent par paires :
          une tache correspond à un pôle nord magnétique, l’autre au pôle sud.
          Il se forme alors de puissants arcs magnétiques, autour desquels du
          plasma peut s’agréger après une éruption.{" "}
          <strong>
            Lorsque le champ magnétique ne peut plus assurer la cohérence de
            l’arc
          </strong>
          , celui-ci se rompt et va libérer violemment tout le plasma qu’il
          contenait dans l’espace : c’est l’éjection de masse coronale (CME :
          Coronal Mass Ejection). Si le plasma est libéré en direction de la
          Terre, une aurore boréale peut se produire quelques jours plus tard.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesEjectiondeMasseCoronale")}
          src="/ressources/gif/auroresBorealesEjectiondeMasseCoronale.gif"
          largeur="320"
          langue={page.langue}
        />
        <h3>Les trous coronaux</h3>
        <p>
          Les trous coronaux sont des zones où les lignes du champ magnétique du
          Soleil sont ouvertes vers l’espace. Le plasma solaire n’est alors pas
          retenu et un flux continu est éjecté vers l’espace. Les trous coronaux
          sont de véritables portes ouvertes vers l’intérieur du Soleil : ils
          peuvent aussi être à l’origine des aurores boréales s’ils font face à
          la Terre.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "auroresBorealesUntrouCoronal")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesLeslignesDeChampsMagnetiquesDunTrouCoronal"
            )}
            langue={page.langue}
            largeur={200}
          />
        </div>
        <h3>Le cycle solaire</h3>
        <p>
          Depuis que le Soleil est observé et étudié, il a été constaté qu’un
          pic de formation de taches solaires se produisait{" "}
          <strong>tous les 11 ans</strong>. Mais même au plus faible de ce cycle
          solaire, il est <strong>tout à fait possible</strong> d’observer de
          magnifiques aurores boréales sur Terre. Elles ont pour origine les
          trous coronaux, qui apparaissent très régulièrement à la surface du
          Soleil.
        </p>
      </Section>
      <Section titre="Sur Terre…">
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <h3>L’atmosphère terrestre</h3>
            <p>
              L’atmosphère est <strong>l’enveloppe gazeuse</strong> qui entoure
              la Terre. Elle est composée à 78 % de diazote (N<sub>2</sub>) et
              de dioxygène (O<sub>2</sub>). L’atmosphère est composée de
              différentes couches (v. illustration à droite) et il est admis que
              l’altitude de 100 kilomètres (la limite de Karman) sépare
              l’atmosphère de l’espace.
            </p>
            <h3>L’ionosphère</h3>
            <p>
              L’ionosphère est{" "}
              <strong>la dernière couche atmosphérique terrestre</strong>. Elle
              s’étend de 50 à 1000 kilomètres au-dessus de la surface,
              chevauchant la mésosphère, la thermosphère et l’exosphère (v.
              illustration à droite). L’ionosphère est un plasma, des ions
              oxygène atomique et d’azote atomique baignent dans une « soupe »
              d’électrons :
            </p>
            <ul>
              <li>
                L’oxygène atomique et l’azote atomique proviennent d’un
                phénomène de dissociation du dioxygène et du diazote, provoqué
                par les rayonnements UV et X émis par le Soleil.
              </li>
              <li>
                Ce rayonnement provoque également une ionisation (une perte
                d’électrons) de ces atomes (la « soupe » d’électrons).
              </li>
            </ul>
            <h3>La magnétosphère</h3>
            <p>
              Une magnétosphère correspond à la région se trouvant{" "}
              <strong>
                sous l’influence du champ magnétique d’une planète
              </strong>
              . Il est produit par l’agitation de son noyau métallique. La
              magnétosphère se trouve au-dessus de l’ionosphère. Les
              magnétosphères n’ont pas de limites d’altitude réellement fixées :
              le champ magnétique est constamment déformé par le vent solaire
              que subit une planète.
            </p>
            <p>
              Les magnétosphères sont de véritables{" "}
              <strong>écrans protecteurs</strong> pour les planètes. Ainsi,
              Mars, dont le noyau est devenu très peu actif au fil du temps,
              possède une magnétosphère très faible. En conséquence, toute son
              atmosphère a été soufflée par le vent solaire, et l’eau qui s’y
              trouvait s’est évaporée dans le cosmos.
            </p>
          </div>
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesLescouchesDeLatmosphereTerrestre"
            )}
            langue={page.langue}
            afficherLegende={false}
            largeur={128}
          />
        </div>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "auroresBorealesLeventSolaireSouffleSurLaTerreDeformantLaMagnetosphere"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "auroresBorealesLamagnetosphere")}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="La formation des aurores boréales">
        <h3>L’émission de lumière</h3>
        <p>
          Les particules de plasma solaire pénètrent dans l’ionosphère{" "}
          <strong>au niveau des pôles</strong>. Elles y sont guidées par les
          lignes de champs magnétiques qui y sont présentes. En empruntant ces
          lignes de champs, les particules de plasma vont exciter sur leur
          passage les ions de l’ionosphère, qui vont alors émettre de la
          lumière.
        </p>
        <h3>Les ovales auroraux</h3>
        <p>
          Les particules de plasma arrivent sur Terre au niveau des pôles. Elles
          se déplacent en suivant des circuits électriques complexes reliant la
          magnétosphère à l’ionosphère. Birkeland fut le premier à soupçonner
          l’existence de ces circuits, mais le modèle qu’il a alors proposé a
          été complété depuis.
        </p>
        <p>
          Mis bout à bout, ces circuits électriques forment{" "}
          <strong>un cercle</strong> autour des pôles magnétiques. Un ovale
          lumineux va alors se former : c’est l’ovale auroral.
        </p>
        <p>
          L’ovale auroral se forme aussi bien au <strong>Sud qu’au Nord</strong>{" "}
          : on parle donc d’aurore australe quand l’aurore se forme au Sud
          (autour du pôle Nord magnétique), et d’aurore boréale quand l’aurore
          se forme au Nord (autour du pôle Sud magnétique).
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesUnovaleAuroral")}
          langue={page.langue}
        />
        <h3>La déformation de l’ovale auroral côté nuit</h3>
        <p>
          Les aurores boréales <strong>les plus brillantes</strong> se
          produisent toujours du côté <strong>nuit</strong> (v. photo
          ci-dessus). Ce qui est plutôt paradoxal, car c’est le côté jour de la
          Terre qui est le plus exposé au Soleil. Le schéma ci-dessous va nous
          aider à mieux comprendre le phénomène : la structure complexe de la
          magnétosphère terrestre.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesStructureMagnetosphere")}
          langue={page.langue}
        />
        <p>
          Lors de l’arrivée d’une tempête solaire sur magnétosphère (c’est un «
          orage magnétique »), deux scénarios se produisent :
        </p>
        <ul>
          <li>
            <strong>1.</strong> Les particules de la tempête s’engouffrent par
            les cornets. Ce scénario <strong>ne peut donner lieu</strong> à des
            aurores brillantes, car la base des cornets polaires est très
            étroite et le champ magnétique y est très puissant. Très peu de
            particules pénètrent donc dans l’ionosphère par ce chemin. D’autre
            part, la base des cornets polaires se trouve du côté jour de la
            Terre.
          </li>
          <p />
          <li>
            <strong>2.</strong> Le scénario suivant explique la formation des
            aurores brillantes et sa description est ici simplifiée.{" "}
            <u>Consultez mes références pour plus de détails.</u>
            <p>
              Les particules de plasma sont alors repoussées dans la plasmagaine
              (v. schéma ci-dessous), côté nuit.
            </p>
            <p>
              La plasmagaine est séparée en 2 parties (supérieure et inférieure)
              par une gaine neutre. Lors d’une forte tempête solaire, elles vont
              se remplir. Sous la pression des couches supérieures de la
              magnétosphère (lobes),{" "}
              <strong>les 2 plasmagaines vont aussi s’étirer</strong>. Ces 2
              effets combinés vont avoir pour effet de comprimer la gaine
              neutre. Les parties inférieures et supérieures vont alors se
              rejoindre : il se produit un violent phénomène magnétique,{" "}
              <strong>la « reconnexion ».</strong>
            </p>
            <p>
              Cette reconnexion{" "}
              <strong>va catapulter des particules de plasma</strong> vers
              l’ionosphère : c’est le « sous orage magnétique ». Les particules
              sont alors accélérées et vont pénétrer en nombre et dans
              l’ionosphère, ce qui produira des aurores brillantes. Ce phénomène
              est toutefois encore mal compris par les scientifiques.
            </p>
            <p>
              Enfin, précisons qu’il peut y avoir un sous orage magnétique même
              quand aucun orage magnétique n’est arrivé sur Terre. L’orage
              magnétique <strong>crée juste des conditions favorables</strong>{" "}
              pour qu’un sous-orage magnétique se produise. On peut donc
              observer des aurores même quand il n’y a pas de tempête solaire.
              Elles seront cependant moins brillantes et moins longues.
            </p>
          </li>
        </ul>
        <h3>La position des ovales</h3>
        <p>
          Les ovales auroraux se forment autour des pôles magnétiques et ont une
          largeur variant de 500 à 1000 kilomètres environ. Toutefois, leur
          diamètre va varier en fonction du point où va se produire la
          reconnexion magnétique côté nuit (v. précédemment). Plus elle se fait
          loin, et plus les lignes de champs magnétiques sont tirées, ce qui a
          pour effet de tirer plus au sud (ou plus au nord pour les aurores
          australes) les ovales auroraux.
        </p>
        <p>Par conséquent :</p>
        <ul>
          <li>
            Lors d’un fort sous orage, un observateur qui se trouve trop au nord
            verra l’aurore sur l’horizon Sud.
          </li>
          <li>
            Lorsque le sous-orage n’est pas suffisamment fort, un observateur
            situé trop au sud verra l’aurore sur l’horizon Nord.
          </li>
        </ul>
      </Section>
      <Section titre="En résumé…">
        <p>
          La vidéo suivante est intéressante car elle montre bien l’enchaînement
          des phénomènes qui sont à l’origine des aurores sur Terre. Elle est
          montrée au public du musée Polaria de Tromsø, en Norvège.
        </p>
        <div className="conteneur-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/N5utQxtma2U"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Section>
      <Section titre="Les couleurs des aurores boréales">
        <h3>Le modèle de Bohr-Rutherford</h3>
        <p>
          Les aurores boréales résultent de la <strong>luminescence</strong> de
          la haute atmosphère due à la{" "}
          <strong>désexcitation des molécules de l’atmosphère</strong>. Pour
          mieux comprendre ce phénomène, il nous faut tout d’abord comprendre la
          structure de l’atome.
        </p>
        <p>
          Un atome est composé de particules : des protons et des neutrons
          forment son noyau, pendant que des électrons gravitent autour.{" "}
          <i>
            Attention, le modèle décrit ici est maintenant dépassé par les
            principes de la mécanique quantique, et n’est utilisé qu’à des fins
            pédagogiques.
          </i>
        </p>
        <p>
          Rutherford a été le premier à attribuer une structure{" "}
          <strong>planétaire à l’atome</strong> : les électrons sont séparés par
          du vide et gravitent autour du noyau, comme les planètes autour du
          système solaire.
        </p>
        <Image
          image={obtenirImage(
            images,
            "auroresBorealesLemodeleDeBohr-Rutherford"
          )}
          langue={page.langue}
        />
        <p>
          Niels Bohr a complété le modèle de Rutherford en y apportant les
          contraintes suivantes :
        </p>
        <ul>
          <li>
            Un <strong>électron</strong> ne peut se trouver{" "}
            <strong>que sur une orbite</strong> bien définie autour de l’atome :
            les couches électroniques.
          </li>
          <li>
            Les <strong>électrons</strong> ne peuvent{" "}
            <strong>pas changer de couche</strong> (à moins qu’ils ne soient
            excités, voir plus bas).
          </li>
          <li>
            Chacune de ces couches accueille un nombre{" "}
            <strong>précis d’électrons</strong>.
          </li>
          <li>
            Une couche ne peut accueillir des électrons qu’à condition que la{" "}
            <strong>précédente ait été remplie</strong>. Il existe 7 couches
            électroniques différentes : la couche K est la plus proche du noyau
            (niveau 1, l’orbite « fondamentale »), et la couche Q est la plus
            éloignée (niveau 7). Chacune possède un niveau d’énergie noté « n ».
            L’énergie la plus basse est celle de la couche la plus proche du
            noyau (couche K, n= 1), la plus forte est celle de la couche la plus
            éloignée du noyau (couche Q, n=7).
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "auroresBorealesLemodelePlanetaireDeBohr-Rutherford"
          )}
          langue={page.langue}
        />
        <h3>La luminescence</h3>
        <p>
          Nous venons de voir que d’après Bohr, les électrons ne peuvent se
          trouver que sur des orbites <strong>bien définies</strong> autour du
          noyau de l’atome et qu’ils ne pouvaient en changer.
        </p>
        <p>
          <strong>Sauf s’ils sont excités</strong> : choc avec une autre
          particule (photon, électron libre, etc.), choc avec un atome… Si
          l’énergie apportée est suffisante et n’est pas trop forte, Bohr
          précise que l’électron peut changer d’orbite, pour ensuite revenir à
          sa position d’origine. Mais pour revenir à sa position d’origine (une
          couche de niveau d’énergie moins élevée), l’électron doit{" "}
          <strong>libérer son excédent d’énergie</strong> sous forme de{" "}
          <strong>photon</strong>.
        </p>
        <p>
          Le retour à l’orbite initiale de l’électron peut se faire en une ou
          plusieurs étapes :
        </p>
        <ul>
          <li>Retour à l’orbite initiale de proche en proche.</li>
          <li>Retour à l’orbite initiale en une seule fois.</li>
          <li>Ou retour à l’orbite initiale en sautant quelques orbites.</li>
        </ul>
        <p>
          <strong>La longueur d’onde</strong> du photon émis sera propre{" "}
          <strong>au saut de retour effectué</strong> et à l’atome excité. Si la
          longueur d’onde du photon émis fait partie du domaine du visible (la
          lumière), alors nous percevrons une <strong>couleur</strong>.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesLaluminescence")}
          langue={page.langue}
        />
        <h3>La désactivation collisionnelle</h3>
        <p>
          Un atome excité (donc ayant reçu un surplus d’énergie) a vu un ou
          plusieurs de ses électrons changer d’orbite. Ces électrons se
          retrouvent dans des états instables. Pour qu’ils se restabilisent de
          nouveau… :
        </p>
        <ul>
          <li>
            Ils peuvent redescendre d’une orbite : un niveau d’énergie est perdu
            et un <strong>photon</strong> est émis (c’est la{" "}
            <strong>luminescence</strong>).
          </li>
          <li>
            <strong>Ou entrer en contact</strong> avec un autre élément (atome /
            molécule / ion…) : dans ce cas, le surplus d’énergie est transmis à
            l’élément rencontré, et aucun photon n’est émis :{" "}
            <strong>c’est la désactivation collisionnelle</strong>.
          </li>
        </ul>
        <h3>Finalement…</h3>
        <p>
          Nous avons maintenant tous les éléments pour comprendre la couleur des
          aurores. Elles sont souvent vertes dans leurs parties basses, puis
          rouges dans leurs parties hautes. Ceci s’explique par la variation de
          la densité atmosphérique et de la composition atmosphérique, qui est
          en fonction de l’altitude.
        </p>
        <p>
          <strong>Le rouge et le bleu</strong>
        </p>
        <p>
          Dans les couches hautes de l’ionosphère, la densité atmosphérique
          devient très faible. Si bien que les électrons de l’oxygène ont le
          temps de redescendre de 2 orbites (2 minutes seront nécessaires) pour
          se stabiliser complètement (retour sur leurs orbites fondamentales) :
          ils émettent du rouge. Du bleu-violet peut également apparaître au
          sommet des aurores : dans ce cas, il provient de l’excitation de
          l’hydrogène, de l’azote ionisé et de l’hélium ionisé. Ces gaz plus
          légers que l’oxygène ne se trouvent que dans les plus hautes couches
          de l’atmosphère : il faut une forte tempête solaire pour qu’ils soient
          excités.
        </p>
        <p>
          <strong>Le vert</strong>
        </p>
        <p>
          Dans les couches moyennes de l’ionosphère, l’oxygène atomique est
          prédominant (le dioxygène, plus lourd, se trouve beaucoup plus bas).
          Les électrons de l’atome excité n’ont le temps de redescendre d’orbite
          qu’une seule fois avant d’être désactivés par un autre atome : ils
          émettent du vert.
        </p>
        <p>
          <strong>D’autres couleurs</strong>
        </p>
        <p>
          La composition des couches de basse à moyenne altitude de l’ionosphère
          est très variée : oxygène atomique, azote atomique, azote ionisé…
          L’azote atomique émet du rouge, l’oxygène du vert et l’azote ionisé du
          bleu. Si les particules de vent solaire possèdent suffisamment
          d’énergie pour parvenir jusqu’à ces couches basses avant d’être
          stoppées, une grande palette de couleurs peut être alors observée :
          rose, jaune, blanc…
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesKp888Sur9")}
          langue={page.langue}
        />
        <Image
          image={obtenirImage(images, "auroreTaiga")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Sources et références">
        <ul>
          <li>Wikipedia (UK et Français)</li>
          <li>
            <Lien urlExterne="http://asahi-classroom.gi.alaska.edu/">
              Asashiwa classroom
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://arena.obspm.fr/~luthier/mottez/intro_physique_spatiale/sommaire.html">
              Introduction à la physique spatiale, par l’Observatoire de Paris
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://tel.archives-ouvertes.fr/tel-00803178/document">
              Aurores et échappement de particules au-dessus des calottes
              polaires terrestres
            </Lien>
            , par Alexandra Teste
          </li>
          <li>
            <Lien urlExterne="http://astronomia.fr/">Astronomia</Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.astrosurf.com/luxorion/aurore.htm">
              Luxorion
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.phy6.org/">Phy6</Lien>
          </li>
        </ul>
      </Section>
    </LayoutTutorielFR>
  )
}
